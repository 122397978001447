<template>
	<main class="page-container">
		<!-- 메뉴이동 로직 추가 S (20220714 - hib)  -->
		<div v-if="isMobile" class="box_select is-static">
			<button type="button" id="select_btn" @click="click">커뮤니티</button>
			<ul id="select_list">
				<li v-for="(item, idx) in menus" :key="idx">
					<a :href="item.path">- {{ item.title }}</a>
				</li>
			</ul>
		</div>
		<!-- 메뉴이동 로직 추가 E (20220714 - hib)  -->
		<section class="scomm-section">
			<div class="container">
				<!-- 상단 제목 검색 -->
				<div class="scomm-detail-header">
					<h2 class="scomm-detail-header__title">상세페이지 제작 지원사업 커뮤니티</h2>
					<div class="scomm-main-search">
						<form action="#">
							<fieldset>
								<legend class="sr-only">커뮤니티 검색</legend>

								<input type="search" class="scomm-main-search__input" placeholder="커뮤니티를 검색해 보세요!  " />
								<button type="submit" class="scomm-main-search__submit"><i class="icon-scomm-search"></i><span class="sr-only">검색</span></button>
							</fieldset>
						</form>
					</div>
				</div>
				<!-- //상단 제목 검색 -->
				<div class="scomm-detail-flex">
					<!-- s: 왼쪽 영역 -->
					<div class="flex-0">
						<div class="scomm-detail-info__thumb">
							<img src="~@/assets/img/@tmp/tmp_notice_image_02@3x.jpg" alt="사진" />
						</div>
						<!-- 웹접근성 구현 탭 -->
						<Tabs>
							<TabList>
								<Tab>커뮤니티정보</Tab>
								<Tab>나의 활동</Tab>
							</TabList>
							<TabPanels>
								<TabPanel>
									<!-- 커뮤니티정보 -->
									<div class="scomm-detail-info__panel">
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-member"></i>
												<span class="text">멤버수</span>
											</dt>
											<dd>22명</dd>
										</dl>
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-board"></i>
												<span class="text">게시물 수</span>
											</dt>
											<dd>130건</dd>
										</dl>
									</div>
									<!-- //커뮤니티정보 -->
								</TabPanel>
								<TabPanel>
									<!-- 나의 활동 -->
									<div class="scomm-detail-info__panel">
										<!-- 사용자 정보 -->
										<div class="scomm-detail-user">
											<div class="scomm-detail-user__photo">
												<img src="" alt="" />
											</div>
											<div class="scomm-detail-user__text">
												<span class="scomm-detail-user__name">김가치</span>
												<span class="scomm-detail-user__date">2023.02.01 14:32</span>
											</div>
										</div>
										<!-- //사용자 정보 -->
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-user"></i>
												<span class="text">방문</span>
											</dt>
											<dd>361회</dd>
										</dl>
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-myarticle"></i>
												<span class="text"><a href="">내가 쓴 글 보기</a></span>
											</dt>
											<dd>1개</dd>
										</dl>
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-mycomm"></i>
												<span class="text"><a href="">내가 쓴 댓글 보기</a></span>
											</dt>
											<dd>5개</dd>
										</dl>
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-like"></i>
												<span class="text"><a href="">좋아요 한 글 보기</a></span>
											</dt>
											<dd>1개</dd>
										</dl>
									</div>
									<!-- //나의 활동 -->
								</TabPanel>
							</TabPanels>
						</Tabs>

						<a href="#" class="button-default is-rounded is-primary">
							<span class="is-desktop">가입하기</span>
							<span class="is-mobile"><i class="icon-info-join"></i>가입</span>
						</a>
						<nav class="scomm-detail-info__links">
							<ul>
								<li>
									<a href=""><i class="icon-info-home"></i><span>Home</span></a>
								</li>
								<li>
									<a href=""><i class="icon-info-notice"></i><span>공지사항</span></a>
								</li>
								<li>
									<a href=""><i class="icon-info-data"></i><span>자료실</span></a>
								</li>
							</ul>
						</nav>
					</div>
					<!-- e: 왼쪽 영역 -->
					<!-- s: 오른쪽 영역 -->
					<div class="flex-1">
						<!-- 상세검색 -->
						<div class="scomm-detail-search">
							<div class="scomm-detail-search__cate">
								<ul>
									<li>전체기간</li>
									<li>전체 글</li>
									<li>게시글 + 댓글</li>
								</ul>
								<div class="display-inline-block"><span class="keyword">소상공인</span> 키워드로 검색 결과 : <span class="keyword">152</span> 건</div>
							</div>
							<div class="scomm-detail-search__form">
								<form action="#">
									<fieldset>
										<legend class="sr-only">상세 검색</legend>

										<div class="field">
											<!-- 전체기간 -->
											<div class="control">
												<DropDown ref="dropDown1" id="dropDown1" init="1" placeholder="전체기간">
													<div class="dropdown-item">
														<button type="button" @click="clickDropdown1($event)" class="text-link">
															<span class="dropdown-text">전체기간</span>
														</button>
													</div>
													<div class="dropdown-item">
														<button type="button" @click="clickDropdown1($event)" class="text-link">
															<span class="dropdown-text">1일</span>
														</button>
													</div>
													<div class="dropdown-item">
														<button type="button" @click="clickDropdown1($event)" class="text-link">
															<span class="dropdown-text">1주</span>
														</button>
													</div>
													<div class="dropdown-item">
														<button type="button" @click="clickDropdown1($event)" class="text-link">
															<span class="dropdown-text">1개월</span>
														</button>
													</div>
													<div class="dropdown-item">
														<button type="button" @click="clickDropdown1($event)" class="text-link">
															<span class="dropdown-text">6개월</span>
														</button>
													</div>
													<div class="dropdown-item">
														<button type="button" @click="clickDropdown1($event)" class="text-link">
															<span class="dropdown-text">1년</span>
														</button>
													</div>
													<div class="dropdown-divider"></div>
													<div class="dropdown-item dropdown-item-date">
														<span class="dropdown-text">기간 입력</span>
														<div class="input-date-wrap">
															<input type="date" class="input" />
															<input type="date" class="input" />
															<button type="button" class="button-default is-small" @click="clickDropdown1($event)">설정</button>
														</div>
													</div>
												</DropDown>
											</div>
											<!-- //전체기간 -->

											<!-- 전체글 -->
											<div class="control">
												<DropDown ref="dropDown2" id="dropDown2" init="2" placeholder="전체 글">
													<div class="dropdown-item">
														<button type="button" @click="clickDropdown2($event)" class="text-link">
															<span class="dropdown-text">전체 글</span>
														</button>
													</div>
													<div class="dropdown-item">
														<button type="button" @click="clickDropdown2($event)" class="text-link">
															<span class="dropdown-text">내가 좋아요 한 글</span>
														</button>
													</div>
													<div class="dropdown-item">
														<button type="button" @click="clickDropdown2($event)" class="text-link">
															<span class="dropdown-text">내가 쓴 글</span>
														</button>
													</div>
													<div class="dropdown-item">
														<button type="button" @click="clickDropdown2($event)" class="text-link">
															<span class="dropdown-text">내가 쓴 댓글</span>
														</button>
													</div>
												</DropDown>
											</div>
											<!-- //전체글 -->

											<!-- 게시글 + 댓글 -->
											<div class="control">
												<DropDown ref="dropDown3" id="dropDown3" placeholder="게시글 + 댓글">
													<div class="dropdown-item">
														<button type="button" @click="clickDropdown3($event)" class="text-link">
															<span class="dropdown-text">게시글 + 댓글</span>
														</button>
													</div>
													<div class="dropdown-item">
														<button type="button" @click="clickDropdown3($event)" class="text-link">
															<span class="dropdown-text">제목만</span>
														</button>
													</div>
													<div class="dropdown-item">
														<button type="button" @click="clickDropdown3($event)" class="text-link">
															<span class="dropdown-text">글작성자</span>
														</button>
													</div>
													<div class="dropdown-item">
														<button type="button" @click="clickDropdown3($event)" class="text-link">
															<span class="dropdown-text">댓글 내용</span>
														</button>
													</div>
													<div class="dropdown-item">
														<button type="button" @click="clickDropdown3($event)" class="text-link">
															<span class="dropdown-text">댓글 작성자</span>
														</button>
													</div>
												</DropDown>
											</div>
											<!-- //게시글 + 댓글 -->

											<div class="control control-input">
												<input type="text" class="input" />
											</div>
											<div class="control-submit">
												<button type="submit" class="button-default button-submit is-primary">검색</button>
											</div>
										</div>
									</fieldset>
								</form>
							</div>
						</div>
						<!-- //상세검색 -->
						<!-- 게시글 -->
						<section class="scomm-detail-list">
							<h3 class="sr-only">게시글 목록</h3>
							<ul class="scomm-detail-articles">
								<li>
									<scommunity-article type="full"> </scommunity-article>
								</li>
								<li>
									<scommunity-article> </scommunity-article>
								</li>
								<li>
									<scommunity-article> </scommunity-article>
								</li>
							</ul>
						</section>
					</div>
					<!-- e: 오른쪽 영역 -->
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';

import DropDown from '@/components/common/DropDown';
import ScommunityArticle from '@/components/scommunity/ScommunityArticle';
import PortalPagination from '@/components/PortalPagination';
import { Tabs, Tab, TabList, TabPanels, TabPanel } from 'vue-accessible-tabs';

export default {
	name: 'ScommunityDetailSearch',
	components: {
		ScommunityArticle,
		PortalPagination,
		Tabs,
		Tab,
		TabList,
		TabPanels,
		TabPanel,
		DropDown,
	},
	data: () => ({
		totalCount: 100,
		pageSize: 10,
		pageListSize: 5,
		pageNo: 8,
		previewImage: null,
	}),
	computed: {
		...mapGetters('common', ['isMobile']),
		queries() {
			const result = {};
			return result;
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		onFileSelected() {
			let input = this.$refs.fileInput;
			let file = input.files;
			if (file && file[0]) {
				let reader = new FileReader();
				reader.onload = (e) => {
					this.previewImage = e.target.result;
				};
				reader.readAsDataURL(file[0]);
				this.$emit('input', file[0]);
			}
		},
		cancelUpload() {
			this.previewImage = null;
		},
		clickDropdown1(event) {
			this.$refs.dropDown1.selectDropdownItem(event);
		},
		clickDropdown2(event) {
			this.$refs.dropDown2.selectDropdownItem(event);
		},
		clickDropdown3(event) {
			this.$refs.dropDown3.selectDropdownItem(event);
		},
	},
};
</script>
