<template>
	<div class="dropdown is-right" v-bind:class="{ 'is-active': isDropdownActive }" v-click-outside="onClickOutside">
		<div class="dropdown-trigger" @click="openDropdown()">
			<button type="button" class="button" aria-haspopup="true" :aria-controls="id">
				<span class="dropdown__placeholder">{{ placeholder }}</span>
				<span class="ico">
					<i class="arrow arrow-down" aria-hidden="true"></i>
				</span>
			</button>
		</div>
		<div class="dropdown-menu" :id="id" role="menu" data-value="">
			<div class="dropdown-content">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
import vClickOutside from 'v-click-outside';

export default {
	name: 'DropDown',
	props: {
		id: {
			type: String,
			required: true,
		},
		placeholder: {
			type: String,
			required: true,
		},
		init: {
			type: String,
			required: false,
		},
	},
	directives: {
		clickOutside: vClickOutside.directive,
	},
	data() {
		return {
			isDropdownActive: false,
		};
	},
	mounted() {
		//const thisInit = Number(this.init);
		// const dropdownItems = document.getElementById(this.id).querySelectorAll('.dropdown-item');
		// for (let i = 0; i < dropdownItems.length; i++) {
		// 	if(dropdownItems[i].getAttribute('data-value') == this.init){
		// 		dropdownItems[i].classList.add('is-active');
		// 		const dropdownText = dropdownItems[i].innerText;
		// 		dropdownItems[i].closest('.dropdown').querySelector('.dropdown__placeholder').innerText = dropdownText;
		// 		document.getElementById(this.id).closest('.dropdown-menu').setAttribute('data-value', this.init);
		// 	} else {
		// 		dropdownItems[i].classList.remove('is-active');
		// 	}
		// }
		// if (this.init) {
		// 	dropdownItems[thisInit - 1].classList.add('is-active');
		// 	const dropdownText = dropdownItems[thisInit - 1].innerText;
		// 	dropdownItems[thisInit - 1].closest('.dropdown').querySelector('.dropdown__placeholder').innerText = dropdownText;
		// }
		this.setDropdownItemByValue(this.init);
	},
	methods: {
		onClickOutside() {
			this.isDropdownActive = false;
		},
		openDropdown() {
			this.isDropdownActive = !this.isDropdownActive;
		},
		selectDropdownItem(event) {
			const dropdownItems = event.target.closest('.dropdown-menu').querySelectorAll('.dropdown-item');
			const dropdownText = event.target.closest('.dropdown-item').querySelector('.dropdown-text').innerText;
			for (let item of dropdownItems) {
				item.classList.remove('is-active');
			}
			event.target.closest('.dropdown-item').classList.add('is-active');
			const dataValue = event.target.closest('.dropdown-item').getAttribute('data-value');
			event.target.closest('.dropdown-menu').setAttribute('data-value', dataValue);
			event.target.closest('.dropdown').querySelector('.dropdown__placeholder').innerText = dropdownText;
			this.onClickOutside();

			const nodes = [...event.target.closest('.dropdown-item').parentElement.children];
			const index = nodes.indexOf(event.target.closest('.dropdown-item'));
			return index;
		},
		selectDropdownItemByTarget(target) {
			const dropdownItems = target.closest('.dropdown-menu').querySelectorAll('.dropdown-item');
			const dropdownText = target.closest('.dropdown-item').querySelector('.dropdown-text').innerText;
			for (let item of dropdownItems) {
				item.classList.remove('is-active');
			}
			target.closest('.dropdown-item').classList.add('is-active');
			const dataValue = target.closest('.dropdown-item').getAttribute('data-value');
			target.closest('.dropdown-menu').setAttribute('data-value', dataValue);
			target.closest('.dropdown').querySelector('.dropdown__placeholder').innerText = dropdownText;
			this.onClickOutside();
		},
		setDropdownItemByValue(dataValue) {
			const dropdownItems = document.getElementById(this.id).querySelectorAll('.dropdown-item');
			for (let i = 0; i < dropdownItems.length; i++) {
				if (dropdownItems[i].getAttribute('data-value') == dataValue) {
					dropdownItems[i].classList.add('is-active');
					const dropdownText = dropdownItems[i].innerText;
					dropdownItems[i].closest('.dropdown').querySelector('.dropdown__placeholder').innerText = dropdownText;
					document.getElementById(this.id).closest('.dropdown-menu').setAttribute('data-value', dataValue);
				} else {
					dropdownItems[i].classList.remove('is-active');
				}
			}
		},
		getDropdownSelectedTitle() {
			return document.getElementById(this.id).closest('.dropdown').querySelector('.dropdown__placeholder').innerText;
		},
		getDropdownSelectedValue() {
			return document.getElementById(this.id).closest('.dropdown-menu').getAttribute('data-value');
		},
		getDropdownSelectedStartDate() {
			return document.getElementById(this.id).closest('.dropdown-menu').querySelector('.dropdown-item-startdate').value;
		},
		getDropdownSelectedEndDate() {
			return document.getElementById(this.id).closest('.dropdown-menu').querySelector('.dropdown-item-enddate').value;
		},
	},
};
</script>
<style scoped></style>
